
// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/login`,
      verify: `${ROOTS.AUTH}/verify`,
      register: `${ROOTS.AUTH}/register`,
      newPassword: `${ROOTS.AUTH}/new-password`,
      firstAccess: `${ROOTS.AUTH}/first-access`,
      forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/`,
    notices: `${ROOTS.DASHBOARD}/bacheca`,
    clocking: `${ROOTS.DASHBOARD}/bollatura`,
    expenseReports: `${ROOTS.DASHBOARD}/note-spese`,
    documents: `${ROOTS.DASHBOARD}/documenti`,
    profile: `${ROOTS.DASHBOARD}/profilo`,
    requests: `${ROOTS.DASHBOARD}/richieste`,
    payrollData: `${ROOTS.DASHBOARD}/dati-contabili`,
    monthlyTimesheet: `${ROOTS.DASHBOARD}/prospetto-mensile`,
    approveRequests: `${ROOTS.DASHBOARD}/approva-richieste`,
  },
};
