import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import * as Sentry from "@sentry/react";

import {AuthGuard} from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import {LoadingScreen} from 'src/components/loading-screen';

import Page500 from "../../pages/500";

// ----------------------------------------------------------------------

// OVERVIEW
const CalendarioPage = lazy(() => import('src/pages/CalendarioPage'));
const BachecaPage = lazy(() => import('src/pages/bacheca/BachecaPage'));
const BollaturaPage = lazy(() => import('src/pages/BollaturaPage'));
const ExpenseReportsPage = lazy(() => import('src/pages/note-spese/ExpenseReportsPage'));
const DocumentsPage = lazy(() => import('src/pages/DocumentsPage'));
const AccountPage = lazy(() => import('src/pages/AccountPage'));
const DatiContabiliPage = lazy(() => import('src/pages/DatiContabiliPage'));
const CompanyRequestPage = lazy(() => import('src/pages/approvazione-richieste/CompanyRequestPage'));
const RequestPage = lazy(() => import('src/pages/richieste/RequestPage'));
const GuestPage = lazy(() => import('src/pages/bollatura/GuestPage'));

// ----------------------------------------------------------------------

export const appRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <Sentry.ErrorBoundary fallback={<Page500/>}>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen/>}>
              <Outlet/>
            </Suspense>
          </DashboardLayout>
        </Sentry.ErrorBoundary>
      </AuthGuard>
    ),
    children: [
      {element: <CalendarioPage/>, index: true},
      {path: 'bacheca', element: <BachecaPage/>},
      {path: 'bollatura', element: <BollaturaPage/>},
      {path: 'note-spese', element: <ExpenseReportsPage/>},
      {path: 'documenti', element: <DocumentsPage/>},
      {path: 'profilo', element: <AccountPage/>},
      {path: 'dati-contabili', element: <DatiContabiliPage/>},
      {path: 'approva-richieste', element: <CompanyRequestPage/>},
      {path: 'richieste', element: <RequestPage/>},
      {path: 'ospiti', element: <GuestPage/>},
      // { path: 'employees/:employee_id', element: <EmployeePage /> },
      // { path: 'timesheet/:year/:month', element: <TimesheetPage /> },
      // { path: 'timesheet/:year/:month/:employee_id', element: <MonthlyTimesheetPage /> },
      // { path: 'documents', element: <DocumentsPage /> },
      // { path: 'timetable', element: <ScadenzarioPage /> },
      // { path: 'note-spese', element: <ExpenseReportsPage /> },
      // { path: 'comunicazioni', element: <ComunicazioniPage /> },
      // { path: 'help-desk', element: <HelpDeskPage /> },
      // { path: 'config', element: <ConfigPage /> },
      // { path: 'account', element: <AccountPage /> },
      // { path: 'requests', element: <RequestsPage /> },
      // { path: 'qr-scan', element: <QrCodePage /> },
    ],
  },
];
