
import apiClient from "./ApiService";

//
// const getCurrentUser = async () => {
//     try {
//         const user = await Auth.currentAuthenticatedUser({
//             bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
//         });
//
//         if (!user.signInUserSession.accessToken.payload["cognito:groups"].includes("Dipendente")) {
//             await Auth.signOut();
//             return null;
//         }
//
//         // Configure axios interceptor to add the authorization header
//         apiClient.interceptors.request.use(
//             async config => {
//                 const authSession = await Auth.currentSession();
//                 const token = authSession.getIdToken().getJwtToken();
//                 config.headers.Authorization = `Bearer ${token}`;
//                 return config;
//             },
//             error => Promise.reject(error)
//         );
//
//         apiClient.interceptors.response.use(
//             response => response,
//             async error => {
//                 if (error.response.status === 401) {
//                     await Auth.signOut();
//                 }
//                 return Promise.reject(error);
//             }
//         );
//
//         // apiClient.defaults.headers.Authorization = `Bearer ${token}`;
//         // Get additional user attributes if they exist
//         const attributes = await Auth.userAttributes(user);
//         userData = await AuthService.getUserData();
//
//         Sentry.setUser({
//             id: user.username,
//             email: user.attributes.email,
//             username: user.username,
//         });
//
//         return {
//             ...user,
//             data: userData.data.user,
//             ...(attributes.map(a => ({[a.Name]: a.Value}))).reduce((acc, cur) => ({...acc, ...cur}), {}) // Convert array of attributes to object of
//         }
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// };

const getUserData = async () => apiClient.get("/user")

const AuthService = {
    getUserData,
};
export default AuthService;
