// dayjs
import 'dayjs/locale/it';
import dayjs from "dayjs";
import merge from 'lodash/merge';

// core (MUI)
import {
  itIT as itITCore,
} from '@mui/material/locale';
// data grid (MUI)
import {
  itIT as itITDataGrid,
} from '@mui/x-data-grid';
// date pickers (MUI)
import {
  itIT as itITDate,
} from '@mui/x-date-pickers/locales';

dayjs.locale('it');

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Italian',
    value: 'it',
    systemValue: merge(itITDate, itITDataGrid, itITCore),
    adapterLocale: 'it',
    icon: 'flagpack:it',
    numberFormat: {
      code: 'it-IT',
      currency: 'EUR',
    },
  },
];

export const defaultLang = allLangs[0]; // Italian

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
