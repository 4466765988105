import imageCompression from 'browser-image-compression';

import apiClient from "./ApiService";

const putSignedUrl = (key) => apiClient.post("/file", {key})

const uploadFile = (file, url) => fetch(url, {
        method: "PUT",
        body: file,
    })

const getSignedUrl = (key) => apiClient.get(`/file?key=${encodeURIComponent(key)}`)

const deleteFile = (key) => apiClient.delete(`/file?key=${encodeURIComponent(key)}`)

const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
        const compressedFile = await imageCompression(file, options);
        return compressedFile;
    } catch (error) {
        return file;
    }
}

const FileService = {
    putSignedUrl,
    uploadFile,
    getSignedUrl,
    deleteFile,
    compressImage,
};

export default FileService;
