import PropTypes from 'prop-types';
import {Amplify} from 'aws-amplify';
import {useMemo, useEffect, useReducer, useCallback} from 'react';
import {
  signIn,
  signOut,
  confirmSignIn,
  confirmSignUp,
  resetPassword,
  getCurrentUser,
  resendSignUpCode,
  fetchAuthSession,
  fetchUserAttributes,
  confirmResetPassword,
} from 'aws-amplify/auth';

import {AMPLIFY_API} from 'src/config-global';

import {AuthContext} from './auth-context';
import apiClient from "../../../services/ApiService";
import AuthService from "../../../services/AuthService";

// ----------------------------------------------------------------------
/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */
// ----------------------------------------------------------------------

/**
 * DOCS: https://docs.amplify.aws/react/build-a-backend/auth/manage-user-session/
 */
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: `${AMPLIFY_API.userPoolId}`,
      userPoolClientId: `${AMPLIFY_API.userPoolWebClientId}`,
    },
  },
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          "HeyJob": { // REQUIRED - Amazon Location Service Map resource name
            style: "VectorEsriStreets", // REQUIRED - String representing the style of map resource
          },
        },
        default: "HeyJob", // REQUIRED - Amazon Location Service Map resource name to set as default
      },
      region: `${AMPLIFY_API.region}`, // REQUIRED - Amazon Location Service Region
    },
  },
});

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === 'REFRESH_USER_DATA') {
    return {
      ...state,
      user: {
        ...state.user,
        data: action.payload.data,
      },
    };
  }
  if (action.type === 'CONFIRM_SIGNIN') {
    return {
      ...state,
      user: null,
      email: action.payload,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState, () => initialState);

  const initialize = useCallback(async () => {
    try {
      const {userId: currentUser} = await getCurrentUser();

      const userAttributes = await fetchUserAttributes();

      const {idToken, accessToken} = (await fetchAuthSession()).tokens ?? {};

      const groups = accessToken.payload['cognito:groups'];

      if (currentUser) {

        if (groups && groups.length > 0 && groups.includes('Dipendente')) {

          apiClient.interceptors.request.use(async (config) => {
            const curIdToken = (await fetchAuthSession()).tokens.idToken ?? idToken;
            config.headers.Authorization = `Bearer ${curIdToken}`;
            return config;
          });

          const userDataRes = await AuthService.getUserData();

          dispatch({
            type: 'INITIAL',
            payload: {
              user: {
                ...userAttributes,
                id: userAttributes.sub,
                displayName: `${userAttributes.given_name} ${userAttributes.family_name}`,
                idToken,
                accessToken,
                groups,
                // role: 'admin',
                data: {
                  ...userDataRes.data.user,
                },
              },
            },
          });

        } else {

          await signOut();

          dispatch({
            type: 'INITIAL',
            payload: {
              user: null,
            },
          });
        }
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {

      try {
        await signOut();
      } catch (err) {
        console.error(err);
      }

      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const {
      isSignedIn,
      nextStep
    } = await signIn({
      username: email,
      password,
    });

    switch (nextStep.signInStep) {
      case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
        return nextStep.signInStep;
      case 'DONE':
      default:
        break;
    }

    if (isSignedIn) {
      const userAttributes = await fetchUserAttributes();

      const {idToken, accessToken} = (await fetchAuthSession()).tokens ?? {};

      const groups = accessToken.payload['cognito:groups'];

      if (groups && groups.length > 0 && groups.includes('Dipendente')) {

        apiClient.interceptors.request.use(async (config) => {
          const curIdToken = (await fetchAuthSession()).tokens.idToken ?? idToken;
          config.headers.Authorization = `Bearer ${curIdToken}`;
          return config;
        });

        const userDataRes = await AuthService.getUserData();

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...userAttributes,
              id: userAttributes.sub,
              displayName: `${userAttributes.given_name} ${userAttributes.family_name}`,
              idToken,
              accessToken,
              groups,
              data: {
                ...userDataRes.data.user,
              },
            },
          },
        });

      } else {
        await signOut();

        throw new Error('Utente non autorizzato ad accedere al portale');
      }
    }

    return 'DONE';
  }, []);

  // FETCH USER DATA
  const fetchUserData = useCallback(async () => {
    const userDataRes = await AuthService.getUserData();
    dispatch({
      type: 'REFRESH_USER_DATA',
      payload: {
        data: {
          ...userDataRes.data.user,
        },
      },
    });
  }, []);

  // REGISTER
  // const register = useCallback(async (email, password, firstName, lastName) => {
  //   await signUp({
  //     username: email,
  //     password,
  //     options: {
  //       userAttributes: {
  //         email,
  //         given_name: firstName,
  //         family_name: lastName,
  //       },
  //     },
  //   });
  // }, []);

  // CONFIRM REGISTER
  const confirmRegister = useCallback(async (email, code) => {
    await confirmSignUp({
      username: email,
      confirmationCode: code,
    });
  }, []);

  const confirmLogin = useCallback(async (newPassword) => {
    await confirmSignIn({
      challengeResponse: newPassword,
    });

    const userAttributes = await fetchUserAttributes();

    const {idToken, accessToken} = (await fetchAuthSession()).tokens ?? {};

    const groups = accessToken.payload['cognito:groups'];

    if (groups && groups.length > 0 && groups.includes('Dipendente')) {

      apiClient.interceptors.request.use(async (config) => {
        const curIdToken = (await fetchAuthSession()).tokens.idToken ?? idToken;
        config.headers.Authorization = `Bearer ${curIdToken}`;
        return config;
      });

      const userDataRes = await AuthService.getUserData();

      dispatch({
        type: 'INITIAL',
        payload: {
          user: {
            ...userAttributes,
            id: userAttributes.sub,
            displayName: `${userAttributes.given_name} ${userAttributes.family_name}`,
            idToken,
            accessToken,
            groups,
            data: {
              ...userDataRes.data.user,
            },
          },
        },
      });
    }
  }, []);

  // RESEND CODE REGISTER
  const resendCodeRegister = useCallback(async (email) => {
    await resendSignUpCode({
      username: email,
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut();
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    await resetPassword({username: email});
  }, []);

  // NEW PASSWORD
  const newPassword = useCallback(async (email, code, password) => {
    await confirmResetPassword({
      username: email,
      confirmationCode: code,
      newPassword: password,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'amplify',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      fetchUserData,
      logout,
      newPassword,
      forgotPassword,
      confirmRegister,
      confirmLogin,
      resendCodeRegister,
    }),
    [
      status,
      state.user,
      //
      login,
      fetchUserData,
      logout,
      newPassword,
      forgotPassword,
      confirmRegister,
      confirmLogin,
      resendCodeRegister,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
