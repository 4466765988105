import {useEffect} from "react";
import {useLocation} from "react-router-dom";

import {Box, Paper} from "@mui/material";

import {useNavData} from "./config-navigation";
import {useAuthContext} from "../../auth/hooks";
import {useSettingsContext} from "../../components/settings";
import NavItem from "../../components/nav-section/mini/nav-item";

export default function Footer() {
  const {user} = useAuthContext();
  const location = useLocation();
  const navData = useNavData();
  const settings = useSettingsContext();

  useEffect(() => {
    console.log('user', user.data);
  }, [user]);

  return (
    <Box sx={{position: 'fixed', bottom: '0', left: '0', right: '0', zIndex: 1,
      background: settings.themeMode === 'light'
        ? 'linear-gradient(0deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%)'
        : 'linear-gradient(0deg, #000000 50%, rgba(255, 255, 255, 0) 100%)'}}>
      <Paper sx={{
        borderRadius: '.75rem', m: '0.75rem', padding: '0.25rem 0rem',
        boxShadow: '0px 12px 24px 8px rgba(145, 158, 171, 0.16)'
      }}
             elevation={0}>
        <Box sx={{display: 'grid', gridAutoColumns: 'minmax(0, 1fr)', gridAutoFlow: 'column'}}>
          {navData[0].items.map((item) => (
            <NavItem active={location.pathname === item.path} depth={1} path={item.path}
                     value={item.path} title={item.title} icon={item.icon} sx={{borderRadius: '.5rem'}} />
          ))}
        </Box>
      </Paper>
    </Box>
  )
}
