// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from 'virtual:pwa-register/react'

import Router from 'src/routes/sections';

import 'src/locales/i18n';
import {AuthProvider} from 'src/auth/context/amplify';

import ProgressBar from 'src/components/progress-bar';

import ThemeProvider from "./theme/index";
import ReloadPrompt from "./sections/ReloadPrompt";
import {LocalizationProvider} from "./locales/index";
import {useScrollToTop} from "./hooks/use-scroll-to-top";
import {MotionLazy} from "./components/animate/motion-lazy";
import {SettingsProvider} from "./components/settings/index";
import SnackbarProvider from "./components/snackbar/snackbar-provider";

export const App = () => {

  const intervalMS = 60 * 60 * 1000

  // const updateServiceWorker =
  useRegisterSW({
    onRegistered(r) {
      if (r) {
        setInterval(() => {
          r.update()
        }, intervalMS)
      }
    }
  })

  useScrollToTop();

  return <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <ProgressBar/>
                <Router/>
                <ReloadPrompt/>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
}
