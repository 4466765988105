import React, {Suspense} from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react';
import { BrowserRouter} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
// eslint-disable-next-line import/no-unresolved

import './main.css'
import {App} from "./app";

// Fix for iOS page auto-zooming on input focus
// if (navigator.userAgent.indexOf('iPhone') > -1) {
//     document
//         .querySelector("[name=viewport]")
//         .setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1");
// }

if (import.meta.env.VITE_ENV !== 'local') {
    Sentry.init({
        dsn: "https://e0c8e3eaa8b3569fdd64c20fb77b25f9@o4505538662694912.ingest.sentry.io/4506167989174272",
        environment: import.meta.env.VITE_ENV,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/api\.heyjob\.peopleincloud\.it/],
            }),
          Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
          }),
            // new Feedback({
            //   // Additional SDK configuration goes in here, for example:
            //   // See below for all available options
            // })
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Suspense>
          <App/>
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
)
