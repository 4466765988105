import apiClient from "./ApiService"

const checkPosition = async (latitude, longitude, accuracy, ip) => apiClient.post("/clocking/check-position", {latitude, longitude, accuracy, ip})

const performClocking = async (latitude, longitude, accuracy, ip) => apiClient.post("/clocking", {latitude, longitude, accuracy, ip})

const addClocking = async (geo_out_of_range) => apiClient.post("/clocking", {geo_out_of_range})

const generateClockingQrCode = async () => apiClient.get("/clocking/qr-code")

const getClockings = async (today = false) => apiClient.get(`/clocking${today ? "?today=true" : ""}`)

const getClockingsByYearMonth = async (year, month) => apiClient.get(`/clocking?year=${year}&month=${month}`)

const getClockingsByDate = async (date) => apiClient.get(`/clocking?date=${date}`)

const getTimesheet = async (year, month) => apiClient.get(`/timesheet?year=${year}&month=${month}`)

const getFirstOpenTimesheet = async () => apiClient.get("employee/timesheet/first-open")

const updateTimesheet = async (date, category, n_hours, protocol_number, attachment_url = null, old_category = null) => apiClient.put("/timesheet/justify", {
  date,
  category,
  n_hours,
  protocol_number,
  attachment_url,
  old_category
})

const updateTimesheetNotes = async (date, notes) => apiClient.put("/timesheet/notes", {
  date,
  notes,
})

const closeTimesheet = async (year, month) => apiClient.post("employee/timesheet/close", {year, month})

const addExtraHoursTimesheet = async (date, category, n_hours) => apiClient.put("/timesheet/extra-hours", {
  date,
  category,
  n_hours,
})

const getNotices = async () => apiClient.get("employee/notice")

const getDocuments = async () => apiClient.get("employee/documents")

const getNotifications = async () => apiClient.get("employee/notifications")

const setNotificationsAsRead = async () => apiClient.put("employee/notifications/mark-as-read")

const addTimesheetRequest = async (request) => apiClient.post("timesheet/requests", request)

const getTimesheetRequests = async () => apiClient.get("timesheet/requests")

const getTimesheetRequestsByDate = async (date) => apiClient.get(`timesheet/requests?date=${date}`)

const getTimesheetRequestsOfToday = async () => apiClient.get(`timesheet/requests?today=true`)

const cancelTimesheetRequest = async (company_id, request_id) => apiClient.post(`company/${company_id}/timesheets/requests/${request_id}/cancel`, {})

const askCancelTimesheetRequest = async (company_id, request_id) => apiClient.post(`company/${company_id}/timesheets/requests/${request_id}/ask-cancel`, {})

const updateProfilePicture = async (id, filename) => apiClient.put("employee/profile-picture", {profile_picture: filename})

const markNoticeAsRead = async (id) => apiClient.put(`notice/${id}`)

const createExpenseReport = async (expenseReport) => apiClient.post("employee/expense-report", expenseReport)

const getExpenseReports = async () => apiClient.get("employee/expense-report")

const getTimeProfile = async () => apiClient.get("employee/time-profile")

const getExpenseReportAttachments = async (expenseReportId) => apiClient.get(`employee/expense-report/${expenseReportId}/attachments`)

const updateEmployeeToken = async (token) => apiClient.put('employee/notification-token', {notification_token: token})

const updateEmployee = async (data) => apiClient.put("employee", data)

const notifyUpdate = async () => apiClient.post("employee/notify-update")

const putSignedUrl = async (path) => apiClient.post("employee/documents", {key: path})

const getDatiContabili = async () => apiClient.get("employee/dati-contabili")

const getGuestOfToday = async () => apiClient.get("employee/guests/today")

const addGuest = async (guest) => apiClient.post("employee/guests", guest)

const updateGuest = async (id, guest) => apiClient.put(`employee/guests/${id}`, guest)

const getExternalLinks = async () => apiClient.get(`/employee/links`)

const getPopups = async () => apiClient.get("/employee/popups")

const exportTimesheetPdf = async (year, month) => apiClient.get(`/employee/timesheet/pdf?year=${year}&month=${month}`)

const getSelfApprovalCategories = async () => apiClient.get("/employee/self-approval-categories")

const EmployeeService = {
  checkPosition,
  performClocking,
  addClocking,
  generateClockingQrCode,
  getClockings,
  getClockingsByYearMonth,
  getTimesheet,
  getFirstOpenTimesheet,
  getClockingsByDate,
  updateTimesheet,
  updateTimesheetNotes,
  closeTimesheet,
  addExtraHoursTimesheet,
  getNotices,
  getDocuments,
  getNotifications,
  setNotificationsAsRead,
  addTimesheetRequest,
  getTimesheetRequests,
  getTimesheetRequestsByDate,
  getTimesheetRequestsOfToday,
  cancelTimesheetRequest,
  askCancelTimesheetRequest,
  updateProfilePicture,
  markNoticeAsRead,
  getExpenseReports,
  createExpenseReport,
  getTimeProfile,
  getExpenseReportAttachments,
  updateEmployeeToken,
  updateEmployee,
  notifyUpdate,
  putSignedUrl,
  getDatiContabili,
  getGuestOfToday,
  addGuest,
  updateGuest,
  getExternalLinks,
  getPopups,
  exportTimesheetPdf,
  getSelfApprovalCategories
};
export default EmployeeService;
