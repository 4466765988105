export function dateCalendar(theme) {
  return {
    MuiDateCalendar: {
      variants: [
        {
          props: {variant: 'timesheet'},
          style: {
            width: '100%',
            maxWidth: '360px',
            minHeight: '370px',
            maxHeight: '370px',
            '& .MuiDayCalendarSkeleton-root': {
              width: '100%',
              maxWidth: '360px',
              maxHeight: '400px',
              minHeight: '300px',
            },
            '& .MuiPickersCalendarHeader-root': {
              paddingLeft: '0px',
              paddingRight: '0px',
            },
            '& .MuiPickersCalendarHeader-labelContainer': {
              color: '#9C9C9C',
              fontWeight: 600,
              marginLeft: '0rem',
              marginRight: 'auto',
              fontSize: '0.875rem',
              textTransform: 'capitalize',
            },
            '& .MuiDayCalendar-header': {
              justifyContent: 'space-between',
            },
            '& .MuiDayCalendar-weekDayLabel': {
              fontSize: '1rem',
              width: '2.75rem',
              height: '2.75rem',
            },
            '& .MuiDayCalendar-weekContainer': {
              justifyContent: 'space-between',
              margin: '4px 0'
            },
            '& .MuiDayCalendarSkeleton-week': {
              margin: '4px 0',
              justifyContent: 'space-between',
            },
            '& .MuiPickersDay-root': {
              fontSize: '0.875rem',
              width: '2.5rem',
              height: '2.5rem',
              borderRadius: '50%',
              margin: '0 4px'
            },
            '& .MuiDayCalendarSkeleton-daySkeleton': {
              fontSize: '1rem',
              width: '2.5rem',
              height: '2.5rem',
              borderRadius: '1rem',
              margin: '0 4px'
            },
            '& .MuiDayCalendar-slideTransition': {
              minHeight: '300px',
            }
          }
        },
      ],
    },
  };
}
