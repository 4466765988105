import React from "react";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import {useTheme} from "@mui/material/styles";
import {Badge, IconButton} from "@mui/material";
import Typography from '@mui/material/Typography';
import {DeleteRounded, PhotoCameraRounded} from "@mui/icons-material";

import {useAuthContext} from 'src/auth/hooks';

import EmployeeService from "../../services/EmployeeService";
import {DialogUpdateProfilePicture} from "../../sections/DialogUpdateProfilePicture";


// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const {user, fetchUserData} = useAuthContext();
  const theme = useTheme();

  const [dialogPicture, setDialogPicture] = React.useState(false);

  const removeProfilePicture = async () => {
    await EmployeeService.updateProfilePicture(user.data.id, null);
    fetchUserData().then()
  }

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{position: 'relative'}}>
          <Badge
            overlap="circular"
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            badgeContent={<IconButton
              size="small" onClick={() =>
              user?.data?.profile_picture_url ? removeProfilePicture().then() : setDialogPicture(true)
            }
              sx={{
                color: user?.data?.profile_picture_url ? theme.palette.error.main : theme.palette.primary.main,
                background: 'white',
                '&:hover': {
                  background: 'white'
                }
              }}
            >
              {!user?.data?.profile_picture_url && <PhotoCameraRounded sx={{fontSize: '1.25rem'}}/>}
              {user?.data?.profile_picture_url && <DeleteRounded sx={{fontSize: '1.25rem'}}/>}
            </IconButton>}
          >
            <Avatar src={user?.data?.profile_picture_url} alt={user?.displayName} sx={{width: 64, height: 64}}>
              {user?.displayName?.charAt(0).toUpperCase()}
            </Avatar>
          </Badge>
        </Box>

        <Stack spacing={0.5} sx={{mb: 2, mt: 1.5, width: 1}}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant="body2" noWrap sx={{color: 'text.disabled'}}>
            {user?.email}
          </Typography>
        </Stack>
      </Stack>

      <DialogUpdateProfilePicture open={dialogPicture} handleClose={() => {
        setDialogPicture(false);
        fetchUserData().then()
      }}/>
    </Stack>
  );
}
