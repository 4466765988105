import React from 'react'
// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from 'virtual:pwa-register/react'

import {Button, Dialog, Typography, DialogTitle, DialogActions, DialogContent} from "@mui/material";


function ReloadPrompt() {
  const reloadSW = 'true';

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      console.log(`Service Worker at: ${swUrl}`)
      // @ts-expect-error just ignore
      if (reloadSW === 'true') {
        // eslint-disable-next-line no-unused-expressions
        r && setInterval(() => {
          r.update()
        }, 20000 /* 20s for testing purposes */)
      }
      else {
        // eslint-disable-next-line prefer-template
        console.log('SW Registered: ' + r)
      }
    },
    onRegisterError(error) {
      console.log('SW registration error', error)
    },
  })

  const close = () => {
    setNeedRefresh(false)
  }

  return (
    needRefresh && <Dialog open={needRefresh} fullWidth maxWidth="xs">
      <DialogTitle>Nuovo aggiornamento disponibile</DialogTitle>
      <DialogContent sx={{ overflow: 'unset' }}>
        <Typography variant="body1" gutterBottom>
          Nuova versione disponibile, clicca su <b>Aggiorna</b> per procedere.
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button variant="outlined" color="inherit" onClick={() => close()}>
          Chiudi
        </Button>
        <Button variant="soft" color="primary" onClick={() => updateServiceWorker(true)}>
          Aggiorna
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReloadPrompt
