import PropTypes from 'prop-types';
import { useState, useEffect, forwardRef } from "react";

import { LoadingButton } from "@mui/lab";
import { useTheme } from '@mui/material/styles';
import { DeleteRounded, AttachFileRounded } from "@mui/icons-material";
import {
  Input,
  Slide,
  Stack,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent
} from "@mui/material";

import FileService from "../services/FileService";
import {useAuthContext} from "../auth/hooks/index";
import EmployeeService from "../services/EmployeeService";

const TransitionSlide = forwardRef((
    props,
    ref,
) => <Slide direction="up" ref={ref} {...props} />);

export const DialogUpdateProfilePicture = ({ open, handleClose }) => {

    const {user} = useAuthContext();
    const theme = useTheme();
    const [attachment, setAttachment] = useState(null);
    const [loading, setLoading] = useState(false);

    const updateProfilePicture = async () => {
        setLoading(true);

        const extension = attachment.name.split('.').pop();
        const filename = `profile.${extension}`;

        const presignedRes = await FileService.putSignedUrl(
            `COMPANIES/${user.data.company.vat_number}/EMPLOYEES/${user.data.fiscal_code}/${filename}`
        );

        // Compress image
        const compressedImage = await FileService.compressImage(attachment);

        // Upload file to S3
        await FileService.uploadFile(compressedImage, presignedRes.data.url);

        await EmployeeService.updateProfilePicture(user.data.id, filename);

        setLoading(false);
        handleClose();
    }

    useEffect(() => {
        setAttachment(null);
        setLoading(false);
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose} TransitionComponent={TransitionSlide}>
          <DialogTitle>Scegli la tua foto profilo</DialogTitle>
            <DialogContent>
                <Stack direction="column" alignItems="center" spacing={2} sx={{ mt: 2 }}>
                    <Typography variant="body1"><Typography variant="caption">{attachment?.name}</Typography></Typography>
                    <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 1 }}>
                        {attachment && <IconButton sx={{ mx: 1, color: theme.palette.error.main }} disabled={loading}
                            onClick={() => setAttachment(null)}>
                            <DeleteRounded />
                        </IconButton>}
                        <label htmlFor="profile-picture-button-file">
                            <Input id="profile-picture-button-file" type="file"
                                onChange={(e) => setAttachment(e.target.files[0])}
                                inputProps={{ accept: 'image/*' }}
                                style={{ display: 'none' }} />
                            {!attachment && <Button variant="contained" disabled={loading} disableElevation component="span" startIcon={<AttachFileRounded />}>
                                Carica
                            </Button>}
                        </label>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <LoadingButton loading={loading} onClick={updateProfilePicture} disabled={attachment == null}>Conferma</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

DialogUpdateProfilePicture.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
};
