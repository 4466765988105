export function bottomNavigation(theme) {
  return {
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          background: 'white',
          borderRadius: '0.75rem',
          boxShadow: '0px 2px 48px 0px rgba(0, 0, 0, 0.13)',
          height: '3.25rem',
        }
      }
    }
  };
}
