export function pickersDay(theme) {
  return {
    MuiPickersDay: {
      variants: [
        {
          props: {variant: 'missing'},
          style: {
            background: theme.palette.error.main,
            color: '#FFF',
            '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
              background: theme.palette.error.main,
              color: '#FFF',
            }
          },
        },
        {
          props: {variant: 'holiday'},
          style: {
            color: 'red',
          },
        },
        {
          props: {variant: 'completed'},
          style: {
            background: theme.palette.primary.main,
            color: '#FFF',
            '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
              background: theme.palette.primary.main,
              color: '#FFF',
            }
          },
        },
        {
          props: {variant: 'request'},
          style: {
            background: theme.palette.info.main,
            color: '#FFF',
            '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
              background: theme.palette.info.main,
              color: '#FFF',
            }
          },
        },
        {
          props: {variant: 'standard'},
          style: {
            // background: '#FFF',
            // color: '#5D5D5D',
            '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
              // background: '#FFF',
              // color: '#5D5D5D',
            }
          },
        },
      ],
      styleOverrides: {
        root: {
          '&.MuiPickersDay-root.MuiPickersDay-today': {
            border: 'none',
            fontWeight: 700,
          },
          '&.Mui-selected': {
            border: 'none',
            boxShadow: '0px 1px 6px 3px rgba(0, 0, 0, 0.08)',
            '&[variant="standard"]': {
              background: 'inherit',
              color: 'inherit',
            },
          }
        },
      }
    },
  }
}
