// ----------------------------------------------------------------------

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export function fDate(date, newFormat) {
  const fm = newFormat || 'DD MMM YYYY';

  return date ? dayjs(new Date(date)).format(fm) : '';
}

export function fTime(date, newFormat) {
  const fm = newFormat || 'HH:mm';

  return date ? dayjs(new Date(date)).format(fm) : '';
}

export function isSameDay(date1, date2) {
  return dayjs(date1).isSame(dayjs(date2), 'day');
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'DD MMM YYYY p';

  return date ? dayjs(new Date(date)).format(fm) : '';
}

export function fTimestamp(date) {
  return date ? dayjs(new Date(date)).unix() : '';
}

export function fToNow(date) {
  return date
    ? dayjs(new Date(date)).fromNow(false)
    : '';
}

export function isBetween(inputDate, startDate, endDate) {
  const date = new Date(inputDate);

  return new Date(date.toDateString()) >= new Date(startDate.toDateString()) &&
    new Date(date.toDateString()) <= new Date(endDate.toDateString());
}

export function isPast(date) {
  return date ? dayjs(date).isBefore(dayjs().tz('Europe/Rome')) : false;
}

export function isFuture(date) {
  return date ? dayjs(date).isAfter(dayjs().tz('Europe/Rome')) : false;
}

export function isAfter(startDate, endDate) {
  return startDate && endDate ? dayjs(startDate).isAfter(dayjs(endDate)) : false;
}

export function sameOrAfter(startDate, endDate) {
  return startDate && endDate ? dayjs(startDate).isSameOrAfter(dayjs(endDate)) : false;
}

export function sameOrBefore(startDate, endDate) {
  return startDate && endDate ? dayjs(startDate).isSameOrBefore(dayjs(endDate)) : false;
}
